import React from 'react';
import {Accordion} from 'react-bootstrap';
import ClubRow from './ClubRow';

function Ranking({data}) {

    return (<>
        {data.map(club => <Accordion key={club.id}>
            <ClubRow  club={club}/>
        </Accordion>)}
    </>);
}

export default Ranking