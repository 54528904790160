import { useEffect } from 'react';
import { useState } from 'react';
import {Form} from 'react-bootstrap';

const CheckBox = (props) => {
  const { name, value, onChange, label } = props

  const [isChecked, setIsChecked] = useState(value)

  const handleChange = () => setIsChecked(checked => !checked)

  useEffect(() => {
    onChange(isChecked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked])

  return (
    <Form>
      <Form.Check
        type="switch"
        id={name}
        label={label}
        checked={value}
        onChange={handleChange}
      />
    </Form>
  )
}

export default CheckBox
