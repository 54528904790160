import React, { useRef } from 'react'

const InputParseFile = ({ callback, onLoadFile, className, label, format }) => {
    const file = useRef()

    const handleFileChosen = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader()

            const extension = file.name.split('.').pop().toLowerCase()
            if (format && extension !== format) {
                reject('Тhe file is not in correct format!')
            } else {
                reader.onload = (e) => {
                    const data = onLoadFile(e.target.result);
                    resolve(data)
                }
                reader.onerror = reject;
                reader.readAsText(file)
            }
        });
    }

    const onFileSelect = (event) => {
        const allFiles = event.target.files
        readAllFiles([...allFiles])
    }

    const readAllFiles = async (allFiles) => {
        const results = await Promise.all(allFiles.map(async (file) => {
            const fileContents = await handleFileChosen(file);
            return fileContents;
        }));
        callback(results);
    }

    return (
        <div>
            <input
                hidden
                type='file'
                ref={file}
                onChange={onFileSelect}
            />
            <button className={className} onClick={(e) => {
                e.preventDefault()
                file.current.click()
            }}>{label}</button>
        </div>
    )
}

export default InputParseFile
