import { useState } from "react"
import DragDropContainer from "./dragdrop/DragDropContainer"
import ClassRow from "./ClassRow"
import { calculateTotalPoints, sortClassResultsByTotal } from "../utils/calculateEventPoints"
import { Table } from "react-bootstrap"


const MatchClassRunners = ({classResult, newResult, save}) => {

    const newRunners = newResult.personResults.reduce((accumulator, value) => ({...accumulator, [value.controlCard]: value.name + ' ' + value.controlCard}), {})
    const list = classResult.personResults.reduce((accumulator, value, index) => {
        const items = []
        if (newRunners[value.controlCard]) {
            items.push({id: value.controlCard, content: newRunners[value.controlCard]})
            delete newRunners[value.controlCard]
        }
        return {...accumulator, [index+1]: {id: value.id, name: value.name + ' ' + value.controlCard, items: items}}
    }, {})
    list[0] = {name: 'All', items: Object.entries(newRunners).map(([k,v]) => ({id: k, content: v}))}

    const [saved, setSaved] = useState(false)
    const [savedData, setSavedData]= useState({personResults: []})

    const onSubmit = (data) => {
        
        const values = Object.values(data)
        classResult.personResults.forEach(person => {
            const value = values.find(v => v.id === person.id)
            if (value && value.items.length > 0) {
                const f = newResult.personResults.find(r => r.controlCard === value.items[0].id)
                person.points = {...person.points, ...f.points}
                calculateTotalPoints(person)
            }
        })
        for (let i=1; i < data.length; i++) {
            console.log(data[i])
        }
        
        data[0].items.forEach(p => {
            const f = newResult.personResults.find(r => r.controlCard === p.id)
            calculateTotalPoints(f)
            classResult.personResults.push(f)
        })

        let id = 1;
        for(let i=0; i < classResult.personResults.length; i++) {
            if (!classResult.personResults[i].id || classResult.personResults[i].id === '0') {
                if (classResult.personResults.find(r => r.id === id+'')) {
                    id++;
                }
                classResult.personResults[i].id = id+'';
                id++;
            }
        }

        sortClassResultsByTotal(classResult)
        setSaved(true)
        setSavedData(classResult)
        save(classResult)
    }

    return <>
        {!saved && <DragDropContainer list={list} onSubmit={(onSubmit)}/>}
        {savedData.personResults.length > 0 && <Table striped bordered size="sm">
                <tbody>
                    <ClassRow classResults={savedData}/>
                </tbody>
            </Table>}
    </>
}

export default MatchClassRunners