import { Accordion } from "react-bootstrap"
import MatchClassRunners from "./MatchClassRunners"
import { useState } from "react"


const MatchRunners = (props) => {

    const [results, setResults] = useState(props.results)

    const save = (data) => {
        setResults(results => {
            const res = results.map(r => {
                if (r.name === data.name) {
                    r.personResults = data.personResults
                }
                return r
            })
            props.onSave(res)
            return res
        })
    }

    return <>
        {results.map(classResult => <Accordion key={classResult.name}>
            <Accordion.Item eventKey={classResult.name}>
                <Accordion.Header>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-8'>{classResult.name}</div>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <MatchClassRunners classResult={classResult} newResult={props.classes.find(c => c.name === classResult.name)} save={save}/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>)}
    </>
}

export default MatchRunners