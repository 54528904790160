import { useState } from 'react';
import DragDropContainer from './dragdrop/DragDropContainer';
import { useEffect } from 'react';

const classesList = [12, 14, 16, 18, 21, 35, 45, 55, 65]


const MatchClasses = ({items, callback}) => {
    const [list, setList] = useState({})

    useEffect(() => {
        setList(classesList.reduce((accumulator, value, index) => (
            {...accumulator, [index*2+1]: {name: 'Men ' + value, items: []}, [index*2 + 2]: {name: 'Women ' + value, items: []}}), {0: {name: 'All', items: items}}))
    }, [items])
    
    const onSubmit = (rows) => {
        const rowsClasses = {...rows}
        delete rowsClasses[0]
        callback(Object.entries(rowsClasses))
    }

    return (
        <DragDropContainer list={list} onSubmit={onSubmit}/>
    );
}

export default MatchClasses;
