import React, { useState } from 'react';
import {calculateEventData, calculatePoints} from '../utils/calculateEventPoints'
import { Table } from 'react-bootstrap';
import ClassRow from './ClassRow';
import MatchClasses from './MatchClasses';
import InputParseFile from './generic/InputParseFile';
import XMLParser from 'react-xml-parser'
import SelectInput from './generic/SelectInput';
import MatchRunners from './MatchRunners';

const EVENT_STAGES = [
    { value: '1', label: 'Stage 1' },
    { value: '2', label: 'Stage 2' },
    { value: '3', label: 'Stage 3' },
    { value: '4', label: 'Stage 4' },
    { value: '5', label: 'Stage 5' },
]

const classesList = [12, 14, 16, 18, 21, 35, 45, 55, 65]
const menList = classesList.map(c => ({name: 'Men ' + c, personResults: []}))
const womenList = classesList.map(c => ({name: 'Women ' + c, personResults: []}))


const BPC = () => {

    const [results, setResults] = useState([...menList, ...womenList])
    const [classesResults, setClassesResults] = useState([])
    const [classes, setClasses] = useState([])
    const [stage, setStage] = useState()

    const onParse = (results) => {
        if (results[0]) {
            const data = calculateEventData(results[0])
            setClassesResults(data)
            setClasses([])
        } 
    }

    const callback = (classesArray) => {
        const obj = classesArray.map(([, object]) => {
            const classObject = {
                name: object.name,
            }
            classObject.personResults = object.items.reduce((accumulator, item) => {
                const classResults = classesResults.find(c => c.id === item.id)
                return [...accumulator, ...classResults.personResults]
            }, [])
            return classObject
        })
        obj.forEach(o => {
            o.personResults.sort((a, b) => a.result - b.result)
            o.personResults.forEach((person, index) => person.points = {[stage.value]: calculatePoints(index)})
            for(let i=1; i < o.personResults.length; i++) {
                if (o.personResults[i].result === o.personResults[i-1].result) o.personResults[i].points = o.personResults[i-1].points
            }
            o.personResults.forEach(r => {delete r.result})
        })
        setClasses(obj)
    }

    const onSaveToFile = () => {
        const blob = new Blob([JSON.stringify(results)], { type: "text/plain" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.download = "user-info.json";
        link.href = url;

        link.click()
    }

    const onLoadJsonFile = (data) => {
        return JSON.parse(data)
    }

    const onLoadXMLFile = (data) => {
        return new XMLParser().parseFromString(data)
    }

    const onStageChange = (event) => {
        const selectedStage = EVENT_STAGES.find(s => s.value === event.value)
        selectedStage && setStage(selectedStage)
    }

    return (
        <div>
            <InputParseFile label="Load" onLoadFile={onLoadJsonFile} callback={(data) => setResults(data[0])} format='json'/>
            {results.length > 0 && <>
                <h2 className='text-center'>Bulgarian Park</h2>
                <Table striped bordered size="sm">
                    <tbody>
                        {results.map(classResults => <ClassRow key={classResults.name} classResults={classResults}/>)}
                    </tbody>
                </Table>
            </>}
            <SelectInput
                name='stage'
                value={stage}
                onChange={onStageChange}
                options={EVENT_STAGES}
                menuPlacement='top'
                label='Stage'/>
            {stage && <>
                <InputParseFile label="Select Files" onLoadFile={onLoadXMLFile} callback={onParse} format='xml' />
                {classesResults.length > 0 && classes.length === 0 && <MatchClasses items={classesResults.map(c => ({id: c.id, content: c.name + ' - ' + c.course.length}))} callback={callback}/>} 
                {classes.length > 0 && <MatchRunners results={results} classes={classes} onSave={data => setResults(data)} />}
                
                {classes.length > 0 && <button className='btn btn-primary' onClick={onSaveToFile}>Save to File</button>}
            </>}
        </div>
    );
};

export default BPC;