import React, { useState } from 'react';
import InputXMLFiles from './generic/InputXMLFiles'
import ClassResults from './ClassResults';
import Ranking from './Ranking';

const Complex = () => {

    const [eventName, setEventName] = useState()
    const [classResults, setClassResults] = useState([])
    const [ranking, setRanking] = useState([])

    const onParse = (results) => {
        if (results[0]) {
            const data = results[0]
            const eventData = data.children.find(c => c.name === 'Event') 
            setEventName(eventData.children.find(d => d.name='Name')?.value)
            setClassResults(data.children.filter(c => c.name === 'ClassResult'))
        } 
    }

    const onSubmit = (classes) => {
        const clubs = {};
        classes.forEach(group => {
            group.personResults.forEach(person => {
                if (!clubs[person.club.id]) {
                    clubs[person.club.id] = {
                        id: person.club.id,
                        name: person.club.name,
                        persons: []
                    }
                }
                clubs[person.club.id].persons.push(person)
            })
        })

        const clubsArray = []
        for (let id in clubs) {
            clubs[id].total = 0
            clubs[id].persons.forEach(person => clubs[id].total += person.points)
            clubsArray.push(clubs[id])
        }
        clubsArray.sort((a, b) => b.total - a.total)
        clubsArray.map((club, index) => club.position = index + 1)
        setRanking(clubsArray)
    }

    return (
        <div>
            <InputXMLFiles label="Select File" onParse={onParse}/>
            {eventName && <h2 className='text-center'>{eventName}</h2>}
            {classResults.length > 0 && ranking.length === 0 && <ClassResults data={classResults} onSubmit={onSubmit}/> }
            {ranking.length > 0 && <Ranking data={ranking} />}
        </div>
    );
};

export default Complex;