import {findAll, findOne} from './util'


const calculatePoints = (position) => {
    const points = [25, 21, 19, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    return points[position] || 0
}

const personOKStatus = (personResult) => {
    const status = findOne(personResult, ['Result', 'Status'])?.value
    return status === 'OK'
}

const convertPersons = (data) => {
    const persons =  data.filter(personOKStatus).map(personResult => {
        const id = findOne(personResult, ['Person', 'Id'])?.value
        const firstName = findOne(personResult, ['Person', 'Name', 'Given'])?.value
        const lastName = findOne(personResult, ['Person', 'Name', 'Family'])?.value
        return {
            id,
            name: `${firstName} ${lastName}`,
            club: {
                id: findOne(personResult, ['Organisation', 'Id'])?.value,
                name: findOne(personResult, ['Organisation', 'Name'])?.value
            },
            controlCard: findOne(personResult, ['Result', 'ControlCard'])?.value,
            result: findOne(personResult, ['Result', 'Time'])?.value,
        }
    })
    return persons
}


const calculateEventData = (data) => {

    const classResults = findAll(data, 'ClassResult')
    const results = classResults.map(group => {
        const className = findOne(group, ['Class', 'Name'])?.value
        const classId = findOne(group, ['Class', 'Id'])?.value
        const personResults = convertPersons(findAll(group, 'PersonResult'))
        return {
            name: className,
            id: classId,
            course: {
                id: findOne(group, ['Course', 'Id'])?.value,
                length: findOne(group, ['Course', 'Length'])?.value
            },
            competitors: personResults.length,
            personResults
        }
    })
    return results
}

const calculateTotalPoints = (person) => {
    person.total = 0
    for(let stage in person.points) {
        person.total += person.points[stage]
    }
}

const sortClassResultsByTotal = (classResults) => {
    classResults.personResults.sort((a, b) => b.total - a.total)
}

export {
    calculateEventData,
    calculatePoints,
    calculateTotalPoints,
    sortClassResultsByTotal
}