import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';

import MainRouter from './components/MainRouter'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<MainRouter/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
