import { DragDropContext } from "react-beautiful-dnd"
import DroppableRow from "./DroppableRow"
import { useState } from "react";
import { useEffect } from "react";


const DragDropContainer = ({list, onSubmit}) => {

    const [rows, setRows] = useState(list);

    useEffect(() => {
        setRows(list)
    }, [list])

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
    
        if (source.droppableId !== destination.droppableId) {
            const sourceRow = rows[source.droppableId];
            const destRow = rows[destination.droppableId];
            const sourceItems = [...sourceRow.items];
            const destItems = [...destRow.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setRows(rows => ({
                ...rows,
                [source.droppableId]: {
                ...sourceRow,
                items: sourceItems
                },
                [destination.droppableId]: {
                ...destRow,
                items: destItems
                }
            }));
        } else {
        const row = rows[source.droppableId];
        const copiedItems = [...row.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setRows(rows => ({
            ...rows,
            [source.droppableId]: {
            ...row,
            items: copiedItems
            }
        }));
        }
    }

    return <div className='row' style={{width: "100%" }}>
        <DragDropContext onDragEnd={onDragEnd}>
            {Object.entries(rows).map(([rowId, row], index) => <DroppableRow className={index ? 'col-6': 'col-12'} key={rowId} row={row} rowId={rowId}/>)}
        </DragDropContext>
        <button className='btn btn-primary' onClick={() => onSubmit(rows)}>Submit</button>
    </div>
}

export default DragDropContainer