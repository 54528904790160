import React from 'react'
import Select from 'react-select'

const SelectInput = (props) => {
  const { name, value, options, onChange, label, error, isSearchable = false, isClearable= false, menuPlacement } = props
  return (
    <div className='form-group has-feedback'>
      {label && <label className='form-control-label' htmlFor={name}>{label}</label>}
      <Select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        options={options}
        menuPlacement={menuPlacement}
        isSearchable={isSearchable}
        isClearable={isClearable}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#ff0066'
          }
        })} />
      {error && <div className='invalid-feedback' style={{display: 'block'}}>{error}</div>}
    </div>
  )
}

export default SelectInput
