import { Draggable, Droppable } from "react-beautiful-dnd";
import { Badge } from "react-bootstrap";


const DroppableRow = ({row, rowId, className}) => {
    return (
        <div key={rowId} className={className}>
            <div className="row" style={{ margin: 8 }}>
                <div className="col-4">{row.name}</div>
                <div className="col-8">
                <Droppable droppableId={rowId} key={rowId} direction="horizontal">
                    {(provided, snapshot) => {
                    return (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "lightgrey",
                            padding: 4,
                            minHeight: 35
                        }}
                        >
                        {row.items.map((item, index) => {
                            return (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                            >
                                {(provided) => {
                                return (
                                    <Badge
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    >
                                    {item.content}
                                    </Badge>
                                );
                                }}
                            </Draggable>
                            );
                        })}
                        {provided.placeholder}
                        </div>
                    );
                    }}
                </Droppable>
                </div>
            </div>
        </div>
    )
}

export default DroppableRow