import { Route, Routes } from 'react-router'
import Complex from './Complex';
import BPC from './BPC';
import MatchClasses from './MatchClasses';

const MainRouter = () => {
  
  return (
    <div className='container main'>
        <Routes>
            <Route exact path='/' element={<Complex/>} />
            <Route exact path='/BPC' element={<BPC/>} />
            <Route exact path='/test' element={<MatchClasses/>} />
        </Routes>
    </div>
  )
}

export default MainRouter
