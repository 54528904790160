import React from 'react';

const ClassRow = ({classResults}) => {
    return (
        <>
            <tr className='table-dark'>
                <th>#</th>
                <th>{classResults.name}</th>
                <th>Club</th>
                <th colSpan={5}>Points</th>
                <th>Total</th>
            </tr>
            {classResults.personResults.map((person, index) => 
                <tr key={index}>
                    <td>{person.position}</td>
                    <td>{person.name}</td>
                    <td>{person.club.name}</td>
                    {[1,2,3,4,5].map(stage => <td key={stage}>{person.points[stage] ? person.points[stage]: '0'} </td>)}
                    <td><b>{person.total}</b></td>
                 </tr>)}
            </>
    );
}

export default ClassRow;