const findOne = (object, attributes) => {
    let result = object
    attributes.forEach(attribute => {
        result = result?.children?.find(c => c.name === attribute)
    });
    return result
} 

const findAll = (object, attribute) => {
    return object.children.filter(c => c.name === attribute)
}

export {
    findOne,
    findAll
}