import React, { useEffect, useState } from 'react';
import {findOne, findAll} from '../utils/util'
import CheckBox from './generic/CheckBox';

const calculatePoints = (status, position) => {
    const points = [13, 11, 9, 7, 6, 5, 4, 3, 2, 1]
    if (status === 'OK') {
        return points[position - 1] || 0
    } 
    return 0
}

const convertPersons = (className, data) => {
    const persons =  data.map(personResult => {
        const id = findOne(personResult, ['Person', 'Id'])?.value
        const firstName = findOne(personResult, ['Person', 'Name', 'Given'])?.value
        const lastName = findOne(personResult, ['Person', 'Name', 'Family'])?.value
        const results = findAll(personResult, 'Result')
        const result = results.filter(result => findOne(result, ['OverallResult']))
        let position
        let status
        if (result.length) {
            position = findOne(result[0], ['OverallResult', 'Position'])?.value
            status = findOne(result[0], ['OverallResult', 'Status'])?.value
        } else {
            position = findOne(personResult, ['Result', 'Position'])?.value
            status = findOne(personResult, ['Result', 'Status'])?.value
        }
        return {
            id,
            name: `${firstName} ${lastName}`,
            club: {
                id: findOne(personResult, ['Organisation', 'Id'])?.value,
                name: findOne(personResult, ['Organisation', 'Name'])?.value
            },
            result: {
                position,
                status
            },
            points: calculatePoints(status, position),
            class: className
        }
    })

    const total = persons.filter(p => p.result.position).length
    persons.forEach(p => p.result.total = total)

    return persons
}

function ClassResults({data, onSubmit}) {

    const [classes, setClasses] = useState([])

    useEffect(() => {
        setClasses(data.map(d => {
            const className = findOne(d, ['Class', 'Name'])?.value
            const personResults = convertPersons(className, findAll(d, 'PersonResult'))
            return {
                checkbox: true,
                name: className,
                competitors: personResults.length,
                personResults
            }
        }))
    }, [data])

    const onCheckBoxChange = (value, name) => {
        setClasses(classes => {
            return classes.map(c => {
                if (c.name === name) {
                    c.checkbox = value
                }
                return c
            })
        })
    }

    const onClickSubmit = () => {
        onSubmit(classes.filter(cl => cl.checkbox))
    }

    return (
        <>
            <ul>
                {classes.map(cl => <li key={cl.name}>
                    <CheckBox value={cl.checkbox} label={`${cl.name} - ${cl.competitors}`} onChange={(value) => onCheckBoxChange(value, cl.name)}/>
                    </li>)}
            </ul>
            <button className='btn btn-primary' onClick={onClickSubmit}>Submit</button>
        </>
    );
}

export default ClassResults;