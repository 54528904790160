import React from 'react';
import {Accordion} from 'react-bootstrap';

const ClubRow = ({club}) => {
    return (
        <Accordion.Item eventKey={club.id}>
            <Accordion.Header>
                <div className='container'>
                    <div className='row'>
                        <div className='col-2'>{club.position}</div>
                        <div className='col-8'>{club.name}</div>
                        <div className='col-2'>{club.total}</div>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <div className='row'>
                    <div className='col-6'>Name</div>
                    <div className='col-2'>Class</div>
                    <div className='col-2'>Position</div>
                    <div className='col-2'>Points</div>
                </div>
                {club.persons.map((person, index) => <div className='row' key={index}>
                    <div className='col-6'>{person.name}</div>
                    <div className='col-2'>{person.class}</div>
                    <div className='col-2'>{person.result?.position}</div>
                    <div className='col-2'>{person.points}</div>
                </div>)}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default ClubRow;